import { Container, Typography } from '@material-ui/core';
import React from 'react';

import Layout from '../components/global/layout';
import LightMode from '../components/global/light-mode';
import VerticalPadding from '../components/global/vertical-padding';
import SEO from '../components/seo';

const NotFoundPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Page Not found" />
      <LightMode>
        <VerticalPadding lg>
          <Container maxWidth={false}>
            <Typography
              variant="h2"
              align="center"
              style={{ marginBottom: 30 }}
            >
              Page Not Found
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              className="lead"
              align="center"
            >
              You just hit a page that doesn&#39;t exist... the sadness.
            </Typography>
          </Container>
        </VerticalPadding>
      </LightMode>
    </Layout>
  );
};

export default NotFoundPage;
